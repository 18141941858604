"use strict";

window.CI = window.CI || {};

(function ($$) {
  class SplitWords {
    /**
     *
     * @param {string} selector 分割したいテキストの直属の親要素のセレクタ
     * @param {object} args {duration: 800, wrapper: 'div', wrapperClass: null, splitter: 'span', splitterClass: null, fullLengthAnimation: false}
     */
    constructor(selector = '.js-split-words', args = {}) {
      this.allTarget = document.querySelectorAll(selector);
      this.duration = args.duration || 800;
      this.speed = args.speed || 800;
      this.wrapper = args.wrapper || 'div';
      this.wrapperClass = args.wrapperClass || null;
      this.splitter = args.splitter || 'span';
      this.splitterClass = args.splitterClass || null;
      this.fullLengthAnimation = (args.fullLengthAnimation === undefined || args.fullLengthAnimation === false) ? 'off' : 'on';

      this.init();
    }

    init() {
      [...this.allTarget].map((target, i) => {
        let txt = target.innerText;
        let txtArr = this.txt2Arr(txt);
        this.generateNewElement(txtArr).then(result => {
          target.innerHTML = '';
          target.appendChild(result);
        });
      });
    }

    /**
     * txt2Arrで生成した配列から１文字ずつ分割されたHTMLを生成して返す
     * @param {array}} txtArr txt2Arrで生成した配列
     * @returns １文字ずつ分割されたHTML
     */
    generateNewElement(txtArr) {
      // エラー回避のためプロミスを返す
      return new Promise(resolve => {
        const result = new DocumentFragment();
        [...txtArr].map((txt, i) => {
          let length = txt.length; // テキストの文字数
          let duration = this.duration / 1000; // ミリ秒を秒に変換
          let delayRate = duration / length; // １文字あたりの遅延レート
          let speed = this.speed / 1000; // ミリ秒を秒に変換

          // wrapperを生成してクラスやスタイルを付与
          let div = document.createElement(this.wrapper);
          div.setAttribute('style', `--speed: ${speed}s`);
          if (this.wrapperClass) div.classList.add(this.wrapperClass);

          [...txt].map((val, j) => {
            // splitterを生成してクラスやスタイルを付与
            let delay = Math.round(delayRate * j * 1000) / 1000;
            let span = document.createElement(this.splitter);
            span.setAttribute('style', `--delay: ${delay}s`);
            if (this.splitterClass) span.classList.add(this.splitterClass);

            if (val.match(/ /)) {
              span.innerHTML = '&nbsp;';
            } else if (val.match(/\n/)) {
              if (this.fullLengthAnimation === 'on') span = document.createElement('br');
            } else {
              span.innerText = val;
            }
            div.appendChild(span);
          });
          result.appendChild(div);
        });
        return resolve(result);
      });
    }

    /**
     * 文字列から配列を生成して返す
     * @param {string} txt １文字ずつ分割したい文字列
     * @returns 文字列を分割するために変換した配列
     */
    txt2Arr(txt) {
      const txtArr = [];
      let result = [];
      let match = txt.match(/(.*?)(\n|$)/g); // 改行もしくは末尾で分割して配列にする

      [...match].map((val, i) => {
        let strArr = [];
        [...val].map((str) => {
          if (!str.match(/\n/)) {
            strArr.push(str);
          } else {
            strArr.push('\n');
          }
        });
        if (strArr.length) txtArr.push(strArr);
      });

      result = txtArr;
      if (this.fullLengthAnimation !== 'off' && txtArr.length > 1) {
        // 全体でひとつのアニメーションをさせるために、配列を整形
        result = [];
        result.push(txtArr.reduce((newArr, elem) => {
          return newArr.concat(elem)
        }, []));
      }
      return result;
    }
  }

  const mv = () => {
    const $mv = document.querySelector('.c-mv');
    window.addEventListener('load', () => {
      $mv.classList.add('is-animated');
    });
  };

  const pickupNewsSlider = () => {
    const swiper = new Swiper('.c-pickup-news', {
      slidesPerView: 1.3,
      spaceBetween: 8,
      breakpoints: {
        960: {
          slidesPerView: 3
        }
      }
    });
  };

  const caseStudySlider = () => {
    const swiper = new Swiper('#CaseStudySlider', {
      slidesPerView: 1.3,
      spaceBetween: 8,
      breakpoints: {
        960: {
          slidesPerView: 3
        }
      }
    });
  };


  class InsightLabSlider {
    constructor() {
      this.swiper = null;
    }

    init() {
      if (this.swiper || $$.fn.isDevices('SP')) return;
      this.swiper = new Swiper('#InsightLabSlider', {
        loop: true,
        autoplay: true,
        slidesPerView: 3,
        spaceBetween: 20,
        centeredSlides: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }
      });
    }

    toggleEnable() {
      if ($$.fn.isDevices('SP')) {
        if (!this.swiper) return;
        this.swiper.destroy();
        this.swiper = null;
      } else {
        this.init();
      }
    };
  }
  const insightLabSlider = new InsightLabSlider();


  ['DOMContentLoaded', 'load', 'resize', 'scroll'].map((event) => {
    window.addEventListener(event, (e) => {

      if (event === 'DOMContentLoaded') {
        mv();
        pickupNewsSlider();
        caseStudySlider();
        insightLabSlider.init();
        const mvTitleSplitWords = new SplitWords('.c-mv__title');
        const mvSubtitleSplitWords = new SplitWords('.c-mv__subtitle');
      } else if (event === 'load') {
      } else if (event === 'scroll') {
      } else if (event === 'resize') {
      }

    });
  });
  $$.settings.mql.addEventListener("change", () => {
    insightLabSlider.toggleEnable();
  });

})(window.CI);
